import React, { useEffect, useState } from "react";
import { Form, Input, Button, Checkbox, Typography, Row, Col } from "antd";
import axiosInstance from "../../axiosInstance";
import { showError, showSuccess } from "../../toastService";
import { useNavigate } from "react-router-dom";

const { Title } = Typography;

const Login: React.FC = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");

  useEffect(() => {
    if (localStorage.getItem("accessToken")) {
      setLoading(false);
      navigate("/dashboard");
    } else {
      setLoading(true);
      localStorage.clear();
      navigate("/");
    }
  }, []);

  const handleSubmit = (values: any) => {
    console.log("Received values:", values);

    const request = {
      email: values.email,
      password: values.password,
    };
    axiosInstance
      .post("/api/v1/useronboarding/login", request)
      .then((res) => {
        if (res.status === 200 && res.data.isSuccess) {
          console.log("Login successful:", res.data);
          const accessToken = res.data.accessToken;
          if (accessToken) {
            localStorage.setItem("accessToken", accessToken);
            showSuccess("Login successful!");
            navigate("/dashboard");
          }
        } else {
          console.error("Unexpected response status:", res.status);
        }
      })
      .catch((error) => {
        if (error.response) {
          const errorMessage =
            error.response.data.message || "An error occurred.";
          showError(errorMessage);
        } else {
          showError("An unexpected error occurred. Please try again later.");
          localStorage.clear();
          navigate("/");
        }
        console.error("Login error:", error);
      });
  };

  return (
    <Row
      justify="center"
      align="middle"
      style={{ height: "100vh", backgroundColor: "#F5F5F5" }}
    >
      <Col xs={24} sm={12} md={8}>
        <div
          style={{
            padding: "2em",
            backgroundColor: "#FFFFFF",
            borderRadius: "8px",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
          }}
        >
          <Title level={2} style={{ textAlign: "center" }}>
            Log in
          </Title>
          <Form
            name="login"
            initialValues={{ remember: true }}
            onFinish={handleSubmit}
          >
            <Form.Item
              name="email"
              rules={[{ required: true, message: "Please input your email!" }]}
            >
              <Input
                placeholder="Username or Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                size="large"
              />
            </Form.Item>
            <Form.Item
              name="password"
              rules={[
                { required: true, message: "Please input your password!" },
              ]}
            >
              <Input.Password
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                size="large"
              />
            </Form.Item>
            <Form.Item>
              <Form.Item name="remember" valuePropName="checked" noStyle>
                <Checkbox>Keep me logged in</Checkbox>
              </Form.Item>
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit" block size="large">
                Log in
              </Button>
            </Form.Item>
            <Form.Item style={{ textAlign: "center" }}>
              <a href="#" style={{ marginRight: "1em" }}>
                Forgot username?
              </a>
              <a href="#" style={{ marginRight: "1em" }}>
                Forgot password?
              </a>
              <a href="#">Can’t log in?</a>
            </Form.Item>
          </Form>
        </div>
      </Col>
    </Row>
  );
};

export default Login;
