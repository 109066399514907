import { UploadOutlined } from "@ant-design/icons";
import { Button, Input, Spin, Tabs, Tooltip, Upload } from "antd";
import TabPane from "antd/es/tabs/TabPane";
import React, { useEffect, useState } from "react";
import axiosInstance from "../../axiosInstance";
import { showError, showSuccess } from "../../toastService";
import LectureDescriptionTab from "./Tabs/LectureDescriptionTab";
import LectureExerciseTab from "./Tabs/LectureExerciseTab";

interface Props {
  lecture: any;
}

const LectureTabs: React.FC<Props> = ({ lecture }) => {
  const [videoLink, setVideoLink] = useState<string>("");
  const [videoLength, setVideoLength] = useState<string>("");
  const [previousState, setPreviousState] = useState<{
    videoLink: string;
    videoLength: string;
  }>({
    videoLink: "",
    videoLength: "",
  });
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchLectureVideo = async () => {
      setIsLoading(true);
      try {
        const response = await axiosInstance.get(
          `/api/admin/v1/lecture/videolink/${lecture.id}`
        );
        const content = response.data || "";
        setVideoLink(content.videoLink);
        setVideoLength(content.videoLength);
        setPreviousState({ videoLink: content.videoLink, videoLength: content.videoLength  });
      } catch (error) {
        showError("Failed to fetch lecture video. Please try again.");
      } finally {
        setIsLoading(false);
      }
    };
    fetchLectureVideo();
  }, [lecture.id]);

  const handleSave = async () => {
    setIsLoading(true);
    try {
      await axiosInstance.put(`/api/admin/v1/lecture/videolink`, {
        lectureId: lecture.id,
        videoLink,
        videoLength,
      });
      showSuccess("Lecture details updated successfully!");
      setPreviousState({ videoLink, videoLength });
    } catch (error) {
      showError("Failed to save lecture details. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  const handleCancel = () => {
    setVideoLink(previousState.videoLink);
    setVideoLength(previousState.videoLength);
  };

  return (
    <div>
      <Tabs defaultActiveKey="1">
        <TabPane tab="Description" key="1">
          <LectureDescriptionTab lecture={lecture} />
        </TabPane>
        <TabPane tab="Video" key="2">
          <Upload
            disabled
            action={`/api/admin/v1/lecture/${lecture.id}/upload`}
            listType="text"
            onChange={(info) => {
              if (info.file.status === "done") {
                showSuccess("Video uploaded successfully!");
              } else if (info.file.status === "error") {
                showError("Video upload failed!");
              }
            }}
          >
            <Tooltip title="Disabled due to limited resources">
              <Button disabled icon={<UploadOutlined />}>
                Upload Video
              </Button>
            </Tooltip>
          </Upload>
          {isLoading ? (
            <Spin />
          ) : (
            <div>
              <div
                style={{
                  marginTop: "1rem",
                  display: "flex",
                  alignItems: "center",
                  gap: "1rem",
                }}
              >
                <div style={{ flex: 2 }}>
                  <label style={{ display: "block", marginBottom: "0.5rem" }}>
                    Video Link
                  </label>
                  <Input
                    size="large"
                    placeholder="Enter cloudinary lecture video link"
                    value={videoLink}
                    onChange={(e) => setVideoLink(e.target.value)}
                  />
                </div>
                <div style={{ flex: 1 }}>
                  <label style={{ display: "block", marginBottom: "0.5rem" }}>
                    Video Length (hh:mm)
                  </label>
                  <Input
                    size="large"
                    placeholder="Enter video length (in minutes)"
                    value={videoLength}
                    onChange={(e) => setVideoLength(e.target.value)}
                  />
                </div>
              </div>
              <div style={{marginTop : "1rem"}}>
                <Button onClick={handleCancel}>Cancel</Button>
                <Button type="primary" onClick={handleSave} style={{ marginLeft: "0.5rem" }}>
                  Save
                </Button>
              </div>
            </div>
          )}
        </TabPane>
        <TabPane tab="Exercises" key="3">
          <LectureExerciseTab lectureId={lecture.id} />
        </TabPane>
      </Tabs>
    </div>
  );
};

export default LectureTabs;
