import React, { useEffect, useState } from "react";
import {
  Card,
  Row,
  Col,
  Button,
  Typography,
  Skeleton,
} from "antd";
import { ArrowLeftOutlined } from "@ant-design/icons"; // Importing icons
import Statistic from "antd/es/statistic/Statistic";
import { useNavigate, useParams } from "react-router-dom"; // Import useHistory
import CourseFormModal from "../../../../components/courses/CourseFormModal";
import { CourseCardProps } from "../CourseCard";
import axiosInstance from "../../../../axiosInstance";
import { showError, showSuccess } from "../../../../toastService";
import Subject from "./Subject";

interface CourseDetails {
  name: string;
  description: string;
  isActive: boolean;
  isDeleted: boolean;
  totalSales: number;
  totalEnrollments: number;
  averageRating: number;
  amount: number;
  startDate: Date;
  endDate: Date;
}

const { Title, Text } = Typography;

const CourseDetails: React.FC = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [initialValues, setInitialValues] = useState<CourseCardProps>();
  const [loading, setLoading] = useState<boolean>(true);
  const [courseDetails, setCourseDetails] = useState<CourseDetails>();
  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    getCourseDetails();
  }, []);

  const getCourseDetails = () => {
    axiosInstance
      .get(`/api/admin/v1/course/${id}`)
      .then((res) => {
        if (res.status === 200 && res.data.isSuccess) {
          setCourseDetails(res.data.courseDetails);
          setLoading(false);
        } else {
          console.error("Unexpected response status:", res.status);
        }
      })
      .catch((error) => {
        if (error.response) {
          const errorMessage =
            error.response.data.message || "An error occurred.";
          showError(errorMessage);
          navigate("/unauthorized");
        } else {
          showError("An unexpected error occurred. Please try again later.");
          localStorage.clear();
          navigate("/");
        }
      });
  };

  const handleModalCancel = () => {
    setIsModalVisible(false);
  };
  const handleCourseSubmit = (values: CourseCardProps) => {
    setIsModalVisible(false);
    console.log(values);
  };

  const updateActiveStatus = () => {
    axiosInstance
      .put(`/api/admin/v1/course/activate/${id}`)
      .then((res) => {
        if (res.status === 200 && res.data.isSuccess) {
          showSuccess(res.data.message);
          getCourseDetails();
        } else {
          console.error("Unexpected response status:", res.status);
        }
      })
      .catch((error) => {
        if (error.response) {
          const errorMessage =
            error.response.data.message || "An error occurred.";
          showError(errorMessage);
          navigate("/unauthorized");
        } else {
          showError("An unexpected error occurred. Please try again later.");
          localStorage.clear();
          navigate("/");
        }
      });
  };
  const handleDeleteCourse = () => {
    axiosInstance
      .delete(`/api/admin/v1/course/${id}`)
      .then((res) => {
        if (res.status === 200 && res.data.isSuccess) {
          showSuccess(res.data.message);
          getCourseDetails();
        } else {
          console.error("Unexpected response status:", res.status);
        }
      })
      .catch((error) => {
        if (error.response) {
          const errorMessage =
            error.response.data.message || "An error occurred.";
          showError(errorMessage);
          navigate("/unauthorized");
        } else {
          showError("An unexpected error occurred. Please try again later.");
          localStorage.clear();
          navigate("/");
        }
      });
  };

  return (
    <div style={{ padding: "20px" }}>
      <Button
        type="link"
        icon={<ArrowLeftOutlined />}
        onClick={() => navigate("/dashboard/courses")}
        style={{ marginBottom: "20px" }}
      >
        Back
      </Button>

      <Card title="Course Details" style={{ marginBottom: "20px" }}>
        {loading ? (
          <Skeleton active paragraph={{ rows: 1 }} />
        ) : (
          <Title level={2}>{courseDetails?.name}</Title>
        )}
        {loading ? (
          <Skeleton active paragraph={{ rows: 2 }} />
        ) : (
          <Text>{courseDetails?.description}</Text>
        )}

        <Row gutter={16} style={{ marginTop: "20px" }}>
          <Col span={6}>
            {loading ? (
              <Skeleton active title={false} paragraph={{ rows: 1 }} />
            ) : (
              <Statistic
                title="Total Sales"
                value={courseDetails?.totalSales}
                prefix="₹"
              />
            )}
          </Col>
          <Col span={6}>
            {loading ? (
              <Skeleton active title={false} paragraph={{ rows: 1 }} />
            ) : (
              <Statistic
                title="Total Enrollments"
                value={courseDetails?.totalEnrollments}
              />
            )}
          </Col>
          <Col span={6}>
            {loading ? (
              <Skeleton active title={false} paragraph={{ rows: 1 }} />
            ) : (
              <Statistic
                title="Average Rating"
                value={courseDetails?.averageRating}
              />
            )}
          </Col>
          <Col span={6}>
            {loading ? (
              <Skeleton active title={false} paragraph={{ rows: 1 }} />
            ) : (
              <Statistic
                title="Amount"
                value={`${courseDetails?.amount}/- INR`}
              />
            )}
          </Col>
        </Row>
        <Row gutter={16} style={{ marginTop: "20px" }}>
          <Col>
            <Button
              type="primary"
              style={{ marginRight: "10px" }}
              onClick={() => {
                setIsModalVisible(true);
                console.log(initialValues);
                setInitialValues({
                  amount: 9000,
                  courseName: courseDetails?.name ?? "",
                  courseDescription: courseDetails?.description ?? "",
                  endDate: courseDetails?.endDate,
                  startDate: courseDetails?.startDate,
                  enrollments: courseDetails?.totalEnrollments ?? 0,
                  isActive: courseDetails?.isActive ?? false,
                  imageLink: "",
                });
              }}
            >
              Edit
            </Button>
            {courseDetails?.isDeleted ? (
              <Button type="default" danger style={{ marginRight: "10px" }}>
                Revoke Delete
              </Button>
            ) : (
              <Button
                type="default"
                danger
                style={{ marginRight: "10px" }}
                onClick={handleDeleteCourse}
              >
                Delete
              </Button>
            )}
            {courseDetails?.isActive ? (
              <Button
                type="default"
                style={{ marginRight: "10px" }}
                onClick={updateActiveStatus}
              >
                Deactivate
              </Button>
            ) : (
              <Button
                type="default"
                style={{ marginRight: "10px" }}
                onClick={updateActiveStatus}
              >
                Activate
              </Button>
            )}
            <Button type="dashed">Promote</Button>
          </Col>
        </Row>
      </Card>
      <Subject courseId={id} isActive={courseDetails?.isActive} />
      <CourseFormModal
        isVisible={isModalVisible}
        onCancel={handleModalCancel}
        onSubmit={handleCourseSubmit}
        initialValues={initialValues} // Pass the current editing course
      />
    </div>
  );
};

export default CourseDetails;
