import React, { useEffect, useState } from "react";
import { Col, Row, Button, Typography } from "antd";
import CourseCard, { CourseCardProps } from "./CourseCard";
import axiosInstance from "../../../axiosInstance";
import { showError, showSuccess } from "../../../toastService";
import { useNavigate } from "react-router-dom";
import SkeletonLoader from "../../../shared/SkeletonLoader/SkeletonLoader";
import CourseFormModal from "../../../components/courses/CourseFormModal";

const { Paragraph } = Typography;

const initialCourses: CourseCardProps[] = [];

const Courses: React.FC = () => {
  const navigate = useNavigate();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [courses, setCourses] = useState<CourseCardProps[]>(initialCourses);
  const [loading, setLoading] = useState<boolean>(true);
  const [editingCourse, setEditingCourse] = useState<CourseCardProps | null>(null);

  useEffect(() => {
    fetchCourses();
  }, []);

  const fetchCourses = () => {
    axiosInstance
      .get("/api/admin/v1/course")
      .then((res) => {
        if (res.status === 200 && res.data.isSuccess) {
          setCourses(res.data.courses);
          setLoading(false);
          console.log(courses)
        } else {
          console.error("Unexpected response status:", res.status);
        }
      })
      .catch((error) => {
        if (error.response) {
          const errorMessage =
            error.response.data.message || "An error occurred.";
          showError(errorMessage);
          navigate("/unauthorized");
        } else {
          showError("An unexpected error occurred. Please try again later.");
          localStorage.clear();
          navigate("/");
        }
      });
  }

  const showModal = (course?: CourseCardProps) => {
    if (course) {
      setEditingCourse(course);
      setIsModalVisible(true);
    } else {
      setEditingCourse(null);
      setIsModalVisible(true);
    }
  };

  const handleModalCancel = () => {
    setIsModalVisible(false);
    setEditingCourse(null);
  };

  const handleCourseSubmit = (values: CourseCardProps) => {
    if (editingCourse) {
      // Handle editing
      const updatedCourse = {
        ...editingCourse,
        ...values,
      };

      axiosInstance
        .put(`/api/admin/v1/course/update/${editingCourse.courseName}`, updatedCourse) // Adjust the API endpoint as needed
        .then((res) => {
          if (res.status === 200 && res.data.isSuccess) {
            fetchCourses();
            setIsModalVisible(false);
            setEditingCourse(null);
          }
        })
        .catch((error) => {
          if (error.response) {
            const errorMessage =
              error.response.data.message || "An error occurred.";
            showError(errorMessage);
          } else {
            showError("An unexpected error occurred. Please try again later.");
            localStorage.clear();
            navigate("/");
          }
        });
    } else {
      const request = {
        amount: values.amount,
        name: values.courseName,
        description: values.courseDescription,
        startDate: values.startDate,
        endDate: values.endDate,
        imageLink : values.imageLink
      };
      
      axiosInstance
        .post("/api/admin/v1/course/create", request)
        .then((res) => {
          if (res.status === 200 && res.data.isSuccess) {
            setIsModalVisible(false);
            fetchCourses();
            setEditingCourse(null);
          }
        })
        .catch((error) => {
          if (error.response) {
            const errorMessage =
              error.response.data.message || "An error occurred.";
            showError(errorMessage);
          } else {
            showError("An unexpected error occurred. Please try again later.");
            localStorage.clear();
            navigate("/");
          }
        });
    }
  };

  return (
    <div>
      {!loading ? (
        <div style={{ padding: "20px" }}>
          <Button
            type="primary"
            onClick={() => showModal()}
            style={{ marginBottom: "20px", marginLeft: "5px" }}
          >
            Add Course
          </Button>
          <br />
          {courses.length > 0 ? (
            <div style={{display:'flex', flexWrap : 'wrap'}}>
              {courses.map((course, index) => (
                  <CourseCard
                    {...course}
                    // onEdit={() => showModal(course)} // Add edit handler
                  />
              ))}
            </div>
          ) : (
            <Paragraph style={{ marginLeft: "15px" }}>No courses present currently</Paragraph>
          )}

          <CourseFormModal
            isVisible={isModalVisible}
            onCancel={handleModalCancel}
            onSubmit={handleCourseSubmit}
            initialValues={editingCourse || undefined} // Pass the current editing course
          />
        </div>
      ) : (
        <SkeletonLoader />
      )}
    </div>
  );
};

export default Courses;
