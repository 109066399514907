import katex from "katex";


export const renderMath = (content: string) => {
  // Replace HTML entities with proper LaTeX-safe characters
  content = content?.replace(/&nbsp;/g, " "); // Replace non-breaking spaces with regular spaces

  return content
    .replace(/\$\$(.*?)\$\$/g, (match, p1) => {
      return `<div class="math-block">${katex.renderToString(p1)}</div>`;
    })
    .replace(/\\\[([^\]]+)\\\]/g, (match, p1) => {
      return `<div class="math-block">${katex.renderToString(p1)}</div>`;
    })
    .replace(/\$(.*?)\$/g, (match, p1) => {
      return `<span class="math-inline">${katex.renderToString(p1)}</span>`;
    })
    .replace(/\\boxed{(.*?)}/g, (match, p1) => {
      return `<div class="math-block">${katex.renderToString(
        "\\boxed{" + p1 + "}"
      )}</div>`;
    })
    .replace(/\\text{(.*?)}/g, (match, p1) => {
      return `<span class="text">${p1}</span>`;
    })
    .replace(/\\\((.*?)\\\)/g, (match, p1) => {
      // New pattern for inline math using \( ... \)
      return `<span class="math-inline">${katex.renderToString(p1)}</span>`;
    });
};
