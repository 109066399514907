import React from "react";
import { Card, Typography, Tag } from "antd";
import {
  CheckCircleOutlined,
  ExclamationCircleOutlined,
  UsergroupAddOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import "./courses.css";

const { Title, Paragraph } = Typography;

export interface CourseCardProps {
  id?:string;
  courseName: string;
  courseDescription: string;
  enrollments: number;
  isActive: boolean;  
  startDate?:Date;
  endDate?: Date;
  amount:number;
  validity?:Date;
  imageLink : string;
}

const CourseCard: React.FC<CourseCardProps> = ({
  id,
  courseName,
  courseDescription,
  enrollments,
  isActive,
  validity,
  amount
}) => {
  const navigate = useNavigate();
  const formattedDate = moment(validity).format('DD MMMM YYYY');
  return (
    <Card
      onClick={() => {navigate(`${id}`)}}
      className="course-container"
      title={
        <div style={{ display: "flex", alignItems: "flex-end" }}>
          {/* Active/Inactive Icon */}
          {isActive ? (
            <CheckCircleOutlined
              style={{ color: "green", marginRight: 8,marginBottom: 10 }}
            />
          ) : (
            <CloseOutlined
              style={{ color: "red", marginRight: 8,marginBottom: 10 }}
            />
          )}

          {/* Title */}
          <Title level={4} style={{ marginBottom: 5, marginRight: "auto" }}>
            {courseName}
          </Title>

          {/* Active/Inactive Chip */}
          <Tag style={{marginBottom: 8}} color={isActive ? "green" : "red"}>
            {isActive ? "Active" : "Inactive"}
          </Tag>
        </div>
      }
      bordered={false}
    >
      <Paragraph>{courseDescription}</Paragraph>
      <Paragraph>
        <UsergroupAddOutlined style={{ marginRight: 8 }} />
        Enrollments: {enrollments}
      </Paragraph>
      <Paragraph>
        <UsergroupAddOutlined style={{ marginRight: 8 }} />
        Amount: {amount ?? 0}/- Rs
      </Paragraph>
      <Paragraph>
        <ExclamationCircleOutlined style={{ marginRight: 8 }} />
        Validity: {formattedDate}
      </Paragraph>
    </Card>
  );
};

export default CourseCard;
