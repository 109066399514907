import React, { useEffect } from "react";
import { Modal, Form, Input, DatePicker, Button } from "antd";
import moment from "moment";
import { CourseCardProps } from "../../pages/dashboard/courses/CourseCard";

interface CourseFormModalProps {
  isVisible: boolean;
  onCancel: () => void;
  onSubmit: (values: CourseCardProps) => void;
  initialValues?: CourseCardProps; // Pass the course details for editing
}

const CourseFormModal: React.FC<CourseFormModalProps> = ({
  isVisible,
  onCancel,
  onSubmit,
  initialValues,
}) => {
  const [form] = Form.useForm();

  useEffect(() => {
    console.log("Initial Values:", initialValues); // Log initial values
    if (initialValues) {
      form.setFieldsValue({
        ...initialValues,
        startDate: moment(initialValues.startDate), // Convert to moment
        endDate: moment(initialValues.endDate), // Convert to moment  
      });
    }
  }, [initialValues, form]);

  const handleOk = () => {
    form
      .validateFields()
      .then((values) => {
        console.log(values);
        const formattedValues: CourseCardProps = {
          ...values,
        };
        onSubmit(formattedValues);
        // form.resetFields();
      })
      .catch((info) => {
        console.log("Validation Failed:", info);
      });
  };

  return (
    <Modal
      title={initialValues ? "Edit Course" : "Add Course"}
      open={isVisible}
      onCancel={onCancel}
      footer={[
        <Button key="cancel" onClick={onCancel}>
          Cancel
        </Button>,
        <Button key="save" type="primary" onClick={handleOk}>
          Save
        </Button>,
      ]}
    >
      <Form form={form} layout="vertical">
        <Form.Item
          label="Course Name"
          name="courseName"
          rules={[{ required: true, message: "Please enter the course name!" }]}
        >
          <Input placeholder="Enter course name" />
        </Form.Item>
        <Form.Item
          label="Course Description"
          name="courseDescription"
          rules={[{ required: true, message: "Please enter the course description!" }]}
        >
          <Input placeholder="Enter course description" />
        </Form.Item>
        <Form.Item
          label="Course Amount"
          name="amount"
          rules={[{ required: true, message: "Please enter the course amount!" }]}
        >
          <Input type="number" placeholder="Enter course amount" />
        </Form.Item>
        <Form.Item
          label="Start Date"
          name="startDate"
          rules={[{ required: true, message: "Please select the start date!" }]}
        >
          <DatePicker
            format="YYYY-MM-DD"
            placeholder="Select start date"
            style={{ width: "100%" }}
          />
        </Form.Item>
        <Form.Item
          label="End Date"
          name="endDate"
          rules={[{ required: true, message: "Please select the end date!" }]}
        >
          <DatePicker
            format="YYYY-MM-DD"
            placeholder="Select end date"
            style={{ width: "100%" }}
          />
        </Form.Item>
        <Form.Item
          label="Image Link"
          name="imageLink"
          rules={[{ required: true, message: "Please enter image link!" }]}
        >
          <Input type="text" placeholder="Enter course amount" />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default CourseFormModal;
