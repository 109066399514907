import React from "react";
import { Layout, Menu, Typography } from "antd";
import { Link, useNavigate, useLocation } from "react-router-dom"; // Import useLocation from react-router-dom
import {
  HomeOutlined,
  UserOutlined,
  TeamOutlined,
  FileOutlined,
  LogoutOutlined,
  BookOutlined
} from "@ant-design/icons";

const { Sider } = Layout;
const { Title } = Typography;

const Sidebar: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation(); // Get the current location

  const handleLogout = () => {
    localStorage.clear();
    navigate("/");
  };

  // Get the current pathname
  const currentPath = location.pathname;

  // Set the active menu item based on the current pathname
  const getSelectedKey = () => {
    if (currentPath.includes("/dashboard/courses")) return ["2"];
    if (currentPath.includes("/dashboard/subjects")) return ["3"];
    if (currentPath.includes("/dashboard/admins")) return ["4"];
    if (currentPath.includes("/dashboard/others")) return ["5"];
    return ["1"]; // Default to Overview
  };

  return (
    <Sider width={250} style={{ backgroundColor: "#ffffff", height: "100vh" }}>
      <div style={{ padding: "16px" }}>
        <Title level={4} style={{ color: "#333" }}>
          Class Crafter
        </Title>
      </div>
      <Menu
        mode="inline"
        selectedKeys={getSelectedKey()} // Set selected keys based on current path
        style={{ height: "100%", borderRight: 0 }}
      >
        <Menu.Item key="1" icon={<HomeOutlined />}>
          <Link to="/dashboard">Overview</Link>
        </Menu.Item>
        <Menu.Item key="2" icon={<UserOutlined />}>
          <Link to="/dashboard/courses">Courses</Link>
        </Menu.Item>
        <Menu.Item key="3" icon={<BookOutlined />}>
          <Link to="/dashboard/subjects">Subjects</Link>
        </Menu.Item>
        <Menu.Item key="4" icon={<TeamOutlined />}>
          <Link to="/dashboard/admins">Admins</Link>
        </Menu.Item>
        <Menu.Item key="5" icon={<FileOutlined />}>
          <Link to="/dashboard/others">Others</Link>
        </Menu.Item>
        <Menu.Item key="6" icon={<LogoutOutlined />} onClick={handleLogout}>
          Logout
        </Menu.Item>
      </Menu>
    </Sider>
  );
};

export default Sidebar;
