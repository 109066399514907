import { DeleteOutlined, DownOutlined, UpOutlined } from "@ant-design/icons";
import {
  Button,
  Card,
  Divider,
  Form,
  Input,
  List,
  Radio,
  Space,
  Switch,
  Tag,
  Typography,
} from "antd";
import "katex/dist/katex.min.css";
import React, { useEffect, useState } from "react";
import axiosInstance from "../../../axiosInstance";
import { showError, showSuccess } from "../../../toastService";
import { renderMath } from "../../../utils/renderMath";

const { TextArea } = Input;
const { Text, Title } = Typography;

interface Props {
  lectureId: any;
}

const LectureExerciseTab: React.FC<Props> = ({ lectureId }) => {
  const [form] = Form.useForm();
  const [questionPreview, setQuestionPreview] = useState<string>("");
  const [options, setOptions] = useState<string[]>(["", "", "", ""]);
  const [optionPreviews, setOptionPreviews] = useState<string[]>([
    "",
    "",
    "",
    "",
  ]);
  const [optionTypes, setOptionTypes] = useState<string[]>([
    "text",
    "text",
    "text",
    "text",
  ]); // State to handle option types (text/image)
  const [correctOption, setCorrectOption] = useState<number | null>(null);
  const [explanations, setExplanations] = useState<string[]>(["", "", "", ""]);
  const [explanationPreview, setExplanationPreview] = useState<string>(""); // Explanation preview state
  const [imageUrls, setImageUrls] = useState<string[]>([]);
  const [questions, setQuestions] = useState<any[]>([]);
  const [viewMode, setViewMode] = useState<"view" | "add">("view");
  const [difficulty, setDifficulty] = useState<"easy" | "medium" | "hard">(
    "easy"
  ); // Difficulty state
  const [isQuestionVisible, setIsQuestionVisible] = useState<boolean[]>([]); // State for visibility of questions

  useEffect(() => {getQuestions()}, []);

  const getQuestions = async () => {
    await axiosInstance
      .get(`api/admin/v1/lecture/get-exercise-questions/${lectureId}`)
      .then((res) => {
        setQuestions(res.data?.questions)
      });
  };

  // Set initial form values when switching between add and view modes
  useEffect(() => {
    if (viewMode === "add") {
      form.setFieldsValue({
        question: questionPreview,
        options: options,
      });
    }
  }, [viewMode, questionPreview, options]);

  // Add new image URL
  const addImageUrl = () => {
    setImageUrls([...imageUrls, ""]);
  };

  // Update image URL
  const updateImageUrl = (index: number, value: string) => {
    const newImageUrls = [...imageUrls];
    newImageUrls[index] = value;
    setImageUrls(newImageUrls);
  };

  // Add or remove options
  const addOption = () => {
    setOptions([...options, ""]);
    setOptionPreviews([...optionPreviews, ""]);
    setExplanations([...explanations, ""]);
    setOptionTypes([...optionTypes, "text"]); // Add default type as text
  };

  // Handle explanation input for each option
  const handleExplanationChange = (index: number, value: string) => {
    const newExplanations = [...explanations];
    newExplanations[index] = value;
    setExplanations(newExplanations);
    // Update explanation preview
    if (correctOption === index) {
      setExplanationPreview(value);
    }
  };

  // Toggle option type between text and image
  const toggleOptionType = (index: number) => {
    const newOptionTypes = [...optionTypes];
    newOptionTypes[index] = newOptionTypes[index] === "text" ? "image" : "text";
    setOptionTypes(newOptionTypes);
  };

  // Handle question submission
  const handleSubmit = (values: any) => {
    if (correctOption == null) {
      return showError("Please select a correct option!");
    }
    const questionData = {
      question: values.question,
      images: imageUrls.filter((url) => url.trim() !== ""),
      options,
      optionTypes,
      correctOption,
      explanations,
      difficulty, // Add difficulty to the submitted data
    };
    setQuestions([...questions, questionData]);
    setIsQuestionVisible([...isQuestionVisible, true]); // Add visibility state for the new question
    form.resetFields(); // Reset form fields after submitting

    handleSave(questionData);
  };

  // Delete a question
  const handleDelete = (index: number) => {
    const updatedQuestions = questions.filter((_, i) => i !== index);
    setQuestions(updatedQuestions);
    const updatedVisibility = isQuestionVisible.filter((_, i) => i !== index);
    setIsQuestionVisible(updatedVisibility);
  };

  // Save all questions to the console
  const handleSave = async (questionData: any) => {
    console.log("Saved Questions:", questions);
    console.log("lecture id", lectureId);

    try {
      await axiosInstance.post(
        `api/v1/test/lecture-exercise-question/${lectureId}`,
        questionData
      );
      showSuccess("Question added successfully!");

      // Clear other state values
      setOptions(["", "", "", ""]);
      setOptionPreviews(["", "", "", ""]);
      setOptionTypes(["text", "text", "text", "text"]);
      setCorrectOption(null);
      setExplanations(["", "", "", ""]);
      setExplanationPreview("");
      setImageUrls([]);
      setQuestionPreview("");
      setViewMode("view");
    } catch (error) {
      showError("Failed to save lecture details. Please try again.");
    } finally {
    }
  };

  // Cancel and clear all questions
  const handleCancel = () => {
    setQuestions([]);
    setIsQuestionVisible([]);
  };

  // Define background colors based on difficulty
  const getDifficultyColor = (difficulty: "easy" | "medium" | "hard") => {
    switch (difficulty) {
      case "hard":
        return "red";
      case "medium":
        return "orange";
      case "easy":
        return "green";
      default:
        return "gray";
    }
  };

  // Toggle the visibility of a question
  const toggleQuestionVisibility = (index: number) => {
    const updatedVisibility = [...isQuestionVisible];
    updatedVisibility[index] = !updatedVisibility[index];
    setIsQuestionVisible(updatedVisibility);
  };

  return (
    <div style={{ maxWidth: 900 }}>
      <Title level={3}>Lecture Exercises</Title>

      {/* Toggle Between Add and View Modes */}
      <Button
        type="primary"
        onClick={() => setViewMode(viewMode === "add" ? "view" : "add")}
        style={{ marginBottom: 20 }}
      >
        {viewMode === "add" ? "View Questions" : "Add Question"}
      </Button>

      {viewMode === "view" ? (
        // View Questions
        <div>
          <List
            dataSource={questions}
            renderItem={(question, index) => (
              <Card key={index} style={{ marginBottom: 16 }}>
                <div
                  style={{
                    marginBottom: 10,
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  {/* Difficulty Chip */}
                  <Tag
                    color={getDifficultyColor(question.difficulty)}
                    style={{ marginBottom: 10 }}
                  >
                    {question.difficulty.charAt(0).toUpperCase() +
                      question.difficulty.slice(1)}{" "}
                    {/* Capitalize difficulty */}
                  </Tag>
                  <Text strong>Question {index + 1}:</Text>
                  <Button
                    type="text"
                    icon={
                      isQuestionVisible[index] ? (
                        <UpOutlined />
                      ) : (
                        <DownOutlined />
                      )
                    }
                    onClick={() => toggleQuestionVisibility(index)}
                  />
                </div>
                {isQuestionVisible[index] && (
                  <>
                    <div
                      style={{ marginBottom: 10 }}
                      dangerouslySetInnerHTML={{
                        __html: renderMath(question.question),
                      }}
                    />
                    {question.images.map((url: string, idx: number) => (
                      <img
                        key={idx}
                        src={url}
                        alt={`Question ${index + 1} Image ${idx + 1}`}
                        style={{
                          maxWidth: 200,
                          maxHeight: 200,
                          display: "block",
                          marginBottom: 10,
                        }}
                      />
                    ))}
                    <Text strong>Options:</Text>
                    <ul style={{ listStyle: "none", padding: 0 }}>
                      {question.options.map(
                        (option: string, optIndex: number) => (
                          <li
                            key={optIndex}
                            style={{
                              backgroundColor:
                                question.correctOption === optIndex
                                  ? "#d4edda"
                                  : "#f8f9fa",
                              padding: "8px 12px",
                              marginBottom: "8px",
                              borderRadius: "4px",
                              border: "1px solid #ced4da",
                            }}
                          >
                            {question.optionTypes[optIndex] === "text" ? (
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: renderMath(option),
                                }}
                              />
                            ) : (
                              <img
                                src={option}
                                alt={`Option ${optIndex + 1}`}
                                style={{ maxWidth: 200, maxHeight: 200 }}
                              />
                            )}
                            <br />
                            {question.correctOption === optIndex &&
                              question.explanations[optIndex] && (
                                <Text
                                  type="secondary"
                                  style={{ fontSize: "12px", marginTop: "5px" }}
                                >
                                  Explanation:{" "}
                                  <span
                                    dangerouslySetInnerHTML={{
                                      __html: renderMath(
                                        question.explanations[optIndex]
                                      ),
                                    }}
                                  />
                                </Text>
                              )}
                          </li>
                        )
                      )}
                    </ul>
                    <Button
                      type="link"
                      icon={<DeleteOutlined />}
                      onClick={() => handleDelete(index)}
                    >
                      Delete
                    </Button>
                  </>
                )}
              </Card>
            )}
          />
          {/* <Space>
            <Button type="primary" onClick={handleSave}>
              Save
            </Button>
            <Button danger onClick={handleCancel}>
              Cancel
            </Button>
          </Space> */}
        </div>
      ) : (
        // Add Question Form
        <Form form={form} layout="vertical" onFinish={handleSubmit}>
          <Title level={4}>Add JEE Question</Title>

          {/* Difficulty Level Selector */}
          <Form.Item
            label="Difficulty Level"
            name="difficulty"
            rules={[
              { required: true, message: "Please enter the difficulty level!" },
            ]}
          >
            <Radio.Group
              onChange={(e) => setDifficulty(e.target.value)}
              value={difficulty}
            >
              <Radio value="easy">Easy</Radio>
              <Radio value="medium">Medium</Radio>
              <Radio value="hard">Hard</Radio>
            </Radio.Group>
          </Form.Item>

          {/* Question Input */}
          <Form.Item
            label="Question Text (Supports LaTeX)"
            name="question"
            rules={[
              { required: true, message: "Please enter the question text!" },
            ]}
          >
            <TextArea
              rows={4}
              onChange={(e) => setQuestionPreview(e.target.value)}
              placeholder="Enter question text with or without LaTeX syntax"
            />
          </Form.Item>

          {/* LaTeX Preview */}
          <Divider />
          <Text strong>LaTeX Preview:</Text>
          <div
            style={{ minHeight: 50, padding: "10px 0" }}
            dangerouslySetInnerHTML={{
              __html: renderMath(questionPreview) || "Type to see preview...",
            }}
          />
          <Divider />

          {/* Image URLs */}
          <Title level={5}>Image URLs</Title>
          {imageUrls.map((url, index) => (
            <Space key={index} style={{ marginBottom: 8 }} align="baseline">
              <Input
                placeholder={`Image URL ${index + 1}`}
                value={url}
                onChange={(e) => updateImageUrl(index, e.target.value)}
              />
            </Space>
          ))}
          <Button type="dashed" onClick={addImageUrl}>
            Add Image URL
          </Button>
          <Divider />

          {/* Options */}
          <Title level={5}>Options (Supports LaTeX)</Title>
          {options.map((option, index) => (
            <Space key={index} style={{ marginBottom: 8 }} align="baseline">
              <Switch
                checked={optionTypes[index] === "image"}
                onChange={() => toggleOptionType(index)}
                checkedChildren="Image"
                unCheckedChildren="Text"
              />
              {optionTypes[index] === "text" ? (
                <Input
                  required
                  placeholder={`Option ${index + 1}`}
                  value={option}
                  onChange={(e) => {
                    const newOptions = [...options];
                    const newOptionPreviews = [...optionPreviews];
                    newOptions[index] = e.target.value;
                    newOptionPreviews[index] = e.target.value;
                    setOptions(newOptions);
                    setOptionPreviews(newOptionPreviews);
                  }}
                />
              ) : (
                <Input
                  required
                  placeholder={`Image URL for Option ${index + 1}`}
                  value={option}
                  onChange={(e) => {
                    const newOptions = [...options];
                    const newOptionPreviews = [...optionPreviews];
                    newOptions[index] = e.target.value;
                    newOptionPreviews[index] = e.target.value;
                    setOptions(newOptions);
                    setOptionPreviews(newOptionPreviews);
                  }}
                />
              )}
              <Radio
                checked={correctOption === index}
                onChange={() => {
                  setCorrectOption(index);
                  setExplanationPreview(explanations[index]); // Set explanation preview when correct option is selected
                }}
              >
                Correct
              </Radio>
            </Space>
          ))}

          {/* Explanation for Correct Option */}
          {correctOption !== null && (
            <Form.Item label="Explanation for Correct Option">
              <TextArea
                rows={2}
                value={explanations[correctOption] || ""}
                onChange={(e) =>
                  handleExplanationChange(correctOption, e.target.value)
                }
                placeholder="Explanation for the selected correct option"
              />
              {/* Explanation Preview */}
              <Divider />
              <Text strong>Explanation Preview:</Text>
              <div
                style={{ minHeight: 50, padding: "10px 0" }}
                dangerouslySetInnerHTML={{
                  __html:
                    renderMath(explanationPreview) || "Type to see preview...",
                }}
              />
              <Divider />
            </Form.Item>
          )}

          <Divider />
          <Text strong>Options Preview:</Text>
          <ul style={{ listStyle: "none", padding: 0 }}>
            {optionPreviews.map((preview, index) => (
              <li
                key={index}
                style={{
                  backgroundColor:
                    correctOption === index ? "#d4edda" : "#f8f9fa",
                  padding: "8px 12px",
                  marginBottom: "8px",
                  borderRadius: "4px",
                  border: "1px solid #ced4da",
                }}
              >
                {optionTypes[index] === "text" ? (
                  <div
                    dangerouslySetInnerHTML={{ __html: renderMath(preview) }}
                  />
                ) : (
                  <img
                    src={preview}
                    alt={`Option ${index + 1}`}
                    style={{ maxWidth: 200, maxHeight: 200 }}
                  />
                )}
              </li>
            ))}
          </ul>

          {/* Add New Option */}
          <Button
            type="dashed"
            onClick={addOption}
            style={{ marginBottom: 20 }}
          >
            Add Option
          </Button>

          <Form.Item>
            <Button type="primary" htmlType="submit" style={{ width: "100%" }}>
              Submit
            </Button>
          </Form.Item>
        </Form>
      )}
    </div>
  );
};

export default LectureExerciseTab;
