import React, { useEffect, useState } from "react";
import { Layout } from "antd";
import Sidebar from "./shared/Sidebar";
import { useNavigate } from "react-router-dom";
import { showError } from "./toastService";

const { Content } = Layout;

interface Props {
  children: React.ReactNode;
}

const App: React.FC<Props> = ({ children }) => {
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  useEffect(() => {
    if (localStorage.getItem("accessToken")) {
      setLoading(false);
    } else {
      showError("Kindly login to proceed!")
      setLoading(true);
      localStorage.clear();
      navigate("/");
    }
  }, []);
  return (
    <Layout style={{ minHeight: "100vh" }}>
      {!loading ? <Sidebar /> : ""}
      {!loading ? (
        <Layout style={{ overflow: "hidden", height: "100vh" }}>
          {" "}
          {/* Prevent scrolling on the main layout */}
          <Content
            style={{ margin: "16px", overflowY: "auto", height: "100vh" }}
          >
            {" "}
            {/* Internal scrolling for content */}
            <div
              style={{ padding: "24px", background: "#fff", minHeight: "100%" }}
            >
              {children}
            </div>
          </Content>
        </Layout>
      ) : (
        ""
      )}
    </Layout>
  );
};

export default App;
