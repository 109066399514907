import React from 'react'

const Dashboard: React.FC = () => {
  return (
    <div>
        Some statistics here!
    </div>
  )
}

export default Dashboard