import React, { useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // import styles

const Admins = () => {
  const [editorContent, setEditorContent] = useState('');

  const handleChange = (value: string) => {
    console.log(JSON.stringify(editorContent));
    setEditorContent(value);
  };

  return (
    <div>
      Dashboard admins
      <ReactQuill
        value={editorContent}
        onChange={handleChange}
        modules={{
          toolbar: [
            [{ header: '1' }, { header: '2' }, { font: [] }],
            [{ list: 'ordered' }, { list: 'bullet' }],
            [{ align: [] }],
            ['bold', 'italic', 'underline', 'strike'],
            ['link'],
            ['blockquote', 'code-block'],
            [{ 'script': 'sub' }, { 'script': 'super' }],
            ['math'],
            ['clean'],
          ],
        }}
      />
    </div>
  )
}

export default Admins
