import { createBrowserRouter, Link } from "react-router-dom";
import Login from "./pages/login/login";
import ErrorBoundary from "./ErrorBoundary";
import NotFound from "./pages/NotFound";
import Dashboard from "./pages/dashboard/Dashboard";
import App from "./App";
import Admins from "./pages/dashboard/admins/Admins";
import Courses from "./pages/dashboard/courses/Courses";
import Unauthorized from "./shared/Unauthorized/Unauthorized";
import CourseDetails from "./pages/dashboard/courses/CourseDetails/CourseDetails";
import SubjectPage from "./pages/dashboard/subjects";
import SubjectDetails from "./pages/dashboard/subjects/subjectDetails";

export const router = createBrowserRouter([
  {
    path: "/",
    element: <Login />,
  },
  {
    path: "unauthorized",
    element: (
      <App>
        <Unauthorized />
      </App>
    ),
  },
  {
    path: "dashboard",
    children: [
      {
        path: "",
        element: (
          <App>
            <Dashboard />
          </App>
        ),
      },
      {
        path: "courses",
        children: [
          {
            path: "",
            element: (
              <App>
                <Courses />
              </App>
            ),
          },
          {
            path: ":id",
            element: (
              <App>
                <CourseDetails />
              </App>
            ),
          },
        ],
      },
      {
        path: "subjects",
        children: [
          {
            path: "",
            element: (
              <App>
                <SubjectPage />
              </App>
            ),
          },
          {
            path: ":id",
            element: (
              <App>
                <SubjectDetails />
              </App>
            ),
          },
        ],
      },
      {
        path: "admins",
        element: (
          <App>
            <Admins />
          </App>
        ),
      },
    ],
  },
  {
    path: "*", // Catch-all route for unmatched paths
    element: (
      <ErrorBoundary>
        <App>
          <NotFound />
        </App>
      </ErrorBoundary>
    ),
  },
]);
