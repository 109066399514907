// axiosInstance.ts
import axios from "axios";
import { createBrowserHistory } from "history";

// Create a history instance
const history = createBrowserHistory();

// Create an instance of axios
const axiosInstance = axios.create({
  baseURL: "https://api.classcrafter.in", // Replace with your API base URL
  timeout: 10000, // Set a timeout as needed
});

axiosInstance.interceptors.request.use(
  (config) => {
    const accessToken = localStorage.getItem("accessToken"); // Replace with your token retrieval method

    if (accessToken) {
      config.headers["Content-Type"] = "application/json";
      config.headers["Authorization"] = `Bearer ${accessToken}`;
    }

    return config;
  }
);

axiosInstance.interceptors.response.use(
  (response) => {
    if (response.status === 401) {
      localStorage.clear();
      history.push("/login");
    }
    return response;
  }
);

export default axiosInstance;
