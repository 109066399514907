import React, { useEffect, useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { Row, Col, Button, Space, Spin } from "antd";
import axiosInstance from "../../../axiosInstance";
import { showError, showSuccess } from "../../../toastService";
import katex from "katex";
import "katex/dist/katex.min.css"; // Make sure katex is included for rendering math
import { renderMath } from "../../../utils/renderMath";

interface Props {
  lecture: any; // Pass lecture ID as a prop
}

const LectureDescriptionTab: React.FC<Props> = ({ lecture }) => {
  const [editorContent, setEditorContent] = useState<string>("");
  const [lastSavedContent, setLastSavedContent] = useState<string>("");
  const [isSaving, setIsSaving] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchLectureContent = async () => {
      setIsLoading(true);
      try {
        const response = await axiosInstance.get(
          `/api/admin/v1/lecture/content/${lecture.id}`
        );
        const content = response.data.content || "";
        setEditorContent(content);
        setLastSavedContent(content);
      } catch (error) {
        showError("Failed to fetch lecture content. Please try again.");
      } finally {
        setIsLoading(false);
      }
    };

    fetchLectureContent();
  }, [lecture]);

  const handleSave = async () => {
    setIsSaving(true);
    try {
      const payload = {
        lectureId: lecture.id,
        content: editorContent,
      };
      await axiosInstance.put(`/api/admin/v1/lecture/update-content`, payload);
      setLastSavedContent(editorContent);
      showSuccess("Lecture content updated successfully!");
    } catch (error) {
      showError("Failed to save the content. Please try again.");
    } finally {
      setIsSaving(false);
    }
  };

  const handleCancel = () => {
    setEditorContent(lastSavedContent);
  };


  const modules = {
    toolbar: [
      [{ header: "1" }, { header: "2" }],
      [{ list: "ordered" }, { list: "bullet" }],
      [{ align: [] }],
      ["bold", "italic", "underline", "strike"],
      ["link", "image"],
      ["blockquote", "code-block"],
      [{ script: "sub" }, { script: "super" }],
      ["clean"],
    ],
  };

  return (
    <div style={{ padding: "16px", fontFamily:'Roboto' }}>
      {isLoading ? (
        <Spin tip="Loading content..." />
      ) : (
        <Row gutter={[16, 16]} justify="center">
          <Col xs={24} lg={12}>
            <h3 style={{ textAlign: "center" }}>Edit Content</h3>
            <ReactQuill
              value={editorContent}
              onChange={setEditorContent}
              theme="snow"
              style={{ height: "260px" }}
            />
            <Space
              style={{
                marginTop: "16px",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Button type="primary" onClick={handleSave} loading={isSaving}>
                Save
              </Button>
              <Button onClick={handleCancel}>Cancel</Button>
            </Space>
          </Col>
          <Col xs={24} lg={12}>
            <h3 style={{ textAlign: "center" }}>Live Preview</h3>
            <div
              style={{
                border: "1px solid #f0f0f0",
                padding: "16px",
                height: "300px",
                overflowY: "auto",
                backgroundColor: "#fafafa",
                borderRadius: "8px",
              }}
              dangerouslySetInnerHTML={{
                __html: renderMath(editorContent),
              }}
            ></div>
          </Col>
        </Row>
      )}
    </div>
  );
};

export default LectureDescriptionTab;
