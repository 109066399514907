import {
  Button,
  Card,
  Col,
  Row,
  Table,
  Tooltip,
  Modal,
  Select,
  Form,
} from "antd";
import React, { useEffect, useState } from "react";
import { SubjectData } from "./data";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import axiosInstance from "../../../../axiosInstance";
import { showError, showSuccess } from "../../../../toastService";
import { useNavigate } from "react-router-dom";

const { Option } = Select;

interface Props {
  courseId: any;
  isActive: boolean | undefined;
}

const Subject: React.FC<Props> = ({ courseId, isActive = false }) => {
  const [subjects, setSubjects] = useState<any[]>([]);
  const [linkedSubjects, setLinkedSubjects] = useState<any[]>([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedSubject, setSelectedSubject] = useState<any>(null);
  const navigate = useNavigate();

  useEffect(() => {
    fetchSubjects();
    fetchLinkedSubjects();
  }, []);

  const subjectsColumns = [
    { title: "Subject Name", dataIndex: "name", key: "subjectName" },
    { title: "Description", dataIndex: "description", key: "subjectCode" },
    {
      title: "Actions",
      key: "actions",
      render: (sub: any, record: SubjectData) => (
        <span>
          <Tooltip title={isActive ? "Cannot unlink when the course is active" : "Unlink subject"}>
            <Button
              disabled={isActive}
              type="link"
              icon={<DeleteOutlined />}
              danger
              onClick={(e) => {unlinkSubject(sub.id)}}
            />
          </Tooltip>
        </span>
      ),
    },
  ];

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedSubject(null); // Reset selection
  };

  const handleSubjectSelect = (value: string) => {
    setSelectedSubject(value);
  };

  const handleLinkSubject = () => {
    if (selectedSubject) {
      // Logic to link the subject
      axiosInstance
        .post(
          `/api/admin/v1/subject/link-subject/${courseId}/${selectedSubject}`
        )
        .then((res: any) => {
          fetchLinkedSubjects();
          showSuccess("Subject linked successfully");
        })
        .catch((error: any) => {
          showError(error.message);
        });
      handleCloseModal();
    } else {
      // Validation for no selection
      console.error("No subject selected!");
    }
  };

  const fetchSubjects = () => {
    axiosInstance
      .get("/api/admin/v1/subject/subjects-to-link")
      .then((res: any) => {
        setSubjects(res.data.subjects);
      })
      .catch((error: any) => {
        showError("An unexpected error occurred. Please try again later.");
        localStorage.clear();
        navigate("/");
      });
  };
  const fetchLinkedSubjects = () => {
    axiosInstance
      .get(`/api/admin/v1/subject/course/${courseId}`)
      .then((res: any) => {
        setLinkedSubjects(res.data.subjects);
      })
      .catch((error: any) => {
        showError("An unexpected error occurred. Please try again later.");
        localStorage.clear();
        navigate("/");
      });
  };
  const unlinkSubject = (subjectId:any) => {
    axiosInstance
      .delete(`/api/admin/v1/subject/unlink-subject/${courseId}/${subjectId}`)
      .then((res: any) => {
        showSuccess("Subject unlinked successfully!")
        fetchLinkedSubjects();
      })
      .catch((error: any) => {
        showError("An unexpected error occurred. Please try again later.");
      });
  };

  return (
    <Card title="Subjects Linked to Course" style={{ marginBottom: "20px" }}>
      <Table pagination={{pageSize : 3}} dataSource={linkedSubjects} columns={subjectsColumns} />
      <Row style={{ marginTop: "20px" }}>
        <Col>
          <Tooltip
            title={
              isActive
                ? "Cannot link subjects while the course is inactive"
                : ""
            }
          >
            <Button
              type="primary"
              style={{ marginRight: "10px" }}
              disabled={isActive}
              onClick={handleOpenModal}
            >
              Link Subject
            </Button>
          </Tooltip>
        </Col>
      </Row>

      {/* Modal for Linking Subject */}
      <Modal
        title="Link Subject"
        open={isModalOpen}
        onCancel={handleCloseModal}
        onOk={handleLinkSubject}
        okText="Link"
        cancelText="Cancel"
      >
        <Form layout="vertical">
          <Form.Item label="Select a Subject" required>
            <Select
              placeholder="Choose a subject"
              onChange={handleSubjectSelect}
              value={selectedSubject || undefined}
            >
              {subjects?.map((sub, key) => {
                return (
                  <Option key={key} value={sub.id}>
                    {sub.name}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>
        </Form>
      </Modal>
    </Card>
  );
};

export default Subject;
