import { Button, Card, Modal, Form, Input, Space, Tooltip, Tag } from "antd";
import React, { useEffect, useState } from "react";
import axiosInstance from "../../../axiosInstance";
import { showError, showSuccess } from "../../../toastService";
import { useNavigate } from "react-router-dom";
import {
  DeleteOutlined,
  EditOutlined,
  EyeOutlined,
  LinkOutlined,
} from "@ant-design/icons";
import "./index.css";

interface Props {}

const SubjectPage: React.FC<Props> = () => {
  const [subjects, setSubjects] = useState<any>([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editingSubject, setEditingSubject] = useState<any>(null); // Holds subject data to edit
  const [form] = Form.useForm();
  const navigate = useNavigate();

  useEffect(() => {
    fetchSubjects();
  }, []);

  const fetchSubjects = () => {
    axiosInstance
      .get("/api/admin/v1/subject")
      .then((res: any) => {
        setSubjects(res.data.subjects);
      })
      .catch((error: any) => {
        showError("An unexpected error occurred. Please try again later.");
        localStorage.clear();
        navigate("/");
      });
  };

  const openAddModal = () => {
    setEditingSubject(null);
    form.resetFields();
    setIsModalOpen(true);
  };

  const openEditModal = (subject: any) => {
    setEditingSubject(subject);
    form.setFieldsValue({
      name: subject.name,
      description: subject.description,
    });
    setIsModalOpen(true);
  };

  const handleSubmit = (values: any) => {
    const subjectData = {
      subjectInfo: {
        name: values.name,
        description: values.description,
      },
    };

    if (editingSubject) {
      // Update existing subject
      axiosInstance
        .put(`/api/admin/v1/subject/update/${editingSubject.id}`, subjectData)
        .then(() => {
          showSuccess("Subject updated successfully!");
          fetchSubjects();
          setIsModalOpen(false);
        })
        .catch(() => showError("Failed to update subject. Please try again."));
    } else {
      // Add new subject
      axiosInstance
        .post("/api/admin/v1/subject/create", subjectData)
        .then((res: any) => {
          showSuccess("Subject added successfully!");
          fetchSubjects();
          setIsModalOpen(false);
        })
        .catch(() => showError("Failed to add subject. Please try again."));
    }
  };

  const handleDelete = (subjectId: string) => {
    Modal.confirm({
      title: "Are you sure you want to delete this subject?",
      content: "This action cannot be undone.",
      onOk: () => {
        axiosInstance
          .delete(`/api/admin/v1/subject/archive/${subjectId}`)
          .then(() => {
            showSuccess("Subject deleted successfully!");
            setSubjects((prevSubjects: any) =>
              prevSubjects.filter((subject: any) => subject.id !== subjectId)
            );
          })
          .catch(() =>
            showError("Failed to delete subject. Please try again.")
          );
      },
      onCancel() {
        // No action needed on cancel
      },
    });
  };

  return (
    <div>
      <Button type="primary" onClick={openAddModal}>
        Add Subject
      </Button>
      <div className="subjectpage-cardWrapper">
        {subjects?.map((sub: any, i: any) => (
          <div key={i} className="subjectpage-card">
            <Card
              title={
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <span>{sub.name}</span>
                  <div>
                    <Tag
                      style={{ marginBottom: 8 }}
                      color={sub.isActive ? "green" : "red"}
                    >
                      {sub.isActive ? "Live" : "Inactive"}
                    </Tag>
                    <Tooltip title="Edit">
                      <EditOutlined
                        onClick={() => openEditModal(sub)}
                        style={{
                          cursor: "pointer",
                          color: "#1890ff",
                          marginLeft: "10px",
                        }}
                      />
                    </Tooltip>
                    <Tooltip title="View Details">
                      <EyeOutlined
                        onClick={() => {
                          navigate(`/dashboard/subjects/${sub.id}`);
                          localStorage.setItem("subName", sub.name);
                          localStorage.setItem("subDesc", sub.description);
                          localStorage.setItem("live", sub.isActive);
                        }}
                        style={{ cursor: "pointer", marginLeft: "10px" }}
                      />
                    </Tooltip>
                    <Tooltip title={sub.isActive ? "Cannot delete live subjects" :"Delete"}>
                      {!sub.isActive ? (
                        <DeleteOutlined
                          onClick={() => handleDelete(sub.id)}
                          style={{
                            color: "#ff4d4f",
                            cursor: "pointer",
                            marginLeft: "10px",
                          }}
                        />
                      ) : (
                        <DeleteOutlined
                          style={{
                            color: "gray",
                            cursor: "not-allowed",
                            marginLeft: "10px",
                          }}
                        />
                      )}
                    </Tooltip>
                  </div>
                </div>
              }
              className="subject-card"
            >
              <p className="subject-description">{sub.description}</p>
            </Card>
          </div>
        ))}
      </div>

      {/* Add/Edit Subject Modal */}
      <Modal
        title={editingSubject ? "Edit Subject" : "Add Subject"}
        open={isModalOpen}
        onCancel={() => setIsModalOpen(false)}
        onOk={() => form.submit()}
      >
        <Form
          form={form}
          layout="vertical"
          onFinish={handleSubmit}
          initialValues={editingSubject}
        >
          <Form.Item
            label="Subject Name"
            name="name"
            rules={[
              { required: true, message: "Please enter the subject name!" },
            ]}
          >
            <Input placeholder="Enter subject name" />
          </Form.Item>
          <Form.Item
            label="Subject Description"
            name="description"
            rules={[
              {
                required: true,
                message: "Please enter the subject description!",
              },
            ]}
          >
            <Input.TextArea placeholder="Enter subject description" rows={4} />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default SubjectPage;
