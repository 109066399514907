import React from 'react';
import { Skeleton, Card } from 'antd';

interface SkeletonLoaderProps {
  count?: number;
}

const SkeletonLoader: React.FC<SkeletonLoaderProps> = ({ count = 3 }) => {
  return (
    <div>
      {[...Array(count)].map((_, index) => (
        <Card key={index} style={{ marginBottom: 16 }}>
          <Skeleton loading={true} active>
            <div>
              {/* Skeleton elements */}
              <Skeleton.Avatar active size="large" shape="circle" />
              <Skeleton.Input style={{ width: 200, marginLeft: 16 }} active />
              <Skeleton.Button style={{ width: 100, marginTop: 16 }} active />
            </div>
          </Skeleton>
        </Card>
      ))}
    </div>
  );
};

export default SkeletonLoader;
